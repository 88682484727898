var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Agenda")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un Agenda")]),_c('h4',[_vm._v(" Si vous souhaitez ajouter un travail, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682600881_AjouterUnEvènement agenda.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier un Agenda")]),_c('h4',[_vm._v(" Pour modifier un événement de l'agenda, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Consulter un evenement Agenda")]),_c('h4',[_vm._v(" Pour consulter un événement dans votre agenda, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682601014_ConsultationEvènementAgenda.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"recherche"}},[_vm._v("Rechercher ou filtrer Agenda")]),_c('h4',[_vm._v(" Pour filtrer les événement dans votre agenda, veuillez suivre les étapes ci-dessous : ")]),_vm._m(3),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec un Agenda, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page agenda.")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau\".")]),_c('li',[_vm._v("Remplissez les informations de l'événement.")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer l'événement. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la page de l'agenda sur laquelle vous souhaitez modifier l'événement. ")]),_c('li',[_vm._v(" Recherchez l'événement à modifier et cliquez dessus pour ouvrir les détails de l'événement. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" ou l'icône d'édition pour accéder au formulaire d'édition de l'événement. ")]),_c('li',[_vm._v(" Modifiez les champs souhaités dans le formulaire d'édition (* il y a des champs obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer les modifications. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de votre agenda.")]),_c('li',[_vm._v(" Trouvez l'événement que vous souhaitez consulter et cliquez dessus. ")]),_c('li',[_vm._v(" Vous verrez alors les détails de l'événement tels que la date, l'heure, la description, etc. ")]),_c('li',[_vm._v(" Si vous avez besoin de modifier ou de supprimer l'événement, vous pouvez le faire à partir de cette page également. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" les critères de filtrage disponible, Vous pouvez filtrer par : utilisateur, statut, mois, semaine ou jour. ")])])
}]

export { render, staticRenderFns }